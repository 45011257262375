import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';

interface BaseConfig {
    apiBaseUrl: string;
    issuerUrl: string;
    redirectUrl: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    private endpoints = new BehaviorSubject<BaseConfig | null>(null);
    readonly endpoints$ = this.endpoints.asObservable().pipe(
        filter(endpoints => !!endpoints)
    )

    get config() {
        return this.endpoints.getValue();
    }

    constructor(private http: HttpClient) { }

    fetchConfig() {
        this.http.get<BaseConfig>(`./../../assets/config/config.json`)
            .subscribe({
                next: (endpoints) => {
                    this.endpoints.next(endpoints);
                },
                error: (err) => {
                    console.log("ERROR THROWN WHILE READING CONFIG FILE!", err);
                }
            })
    }
}