import { Injectable } from '@angular/core';
import { ConfigService } from '../../shared/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = this.configService.config?.apiBaseUrl + '/api';

  public createRawUrl(endpoint: string): string {
    return `${endpoint}`;
  }

  public createApiUrl(endpoint: string): string {
    return `${this.baseUrl}/${endpoint}`;
  }

  public constructor(
    private configService: ConfigService
  ) {   }
}
