import { ResourceInfo } from './../models/resource-info.model';
import { HttpWrapperService } from './../../shared/services/httpWrapper.service';
import { Component, isDevMode } from '@angular/core';

import { UserService } from '../../shared';
import { ConfigService } from '../../shared/config/config.service';

@Component({
  selector: 'tw-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  public API_SERVICE = this.configService.config?.apiBaseUrl;

  public privateInfo: ResourceInfo;
  public publicInfo: ResourceInfo;

  public get isAuthenticated(): boolean {
    return this.user.isAuthenticated;
  }

  public get isAdmin(): boolean {
    return this.user.hasClaim('Administrator');
  }

  public get userName(): string {
    return this.user.userName;
  }

  public constructor(
    private user: UserService,
    private http: HttpWrapperService,
    private configService: ConfigService
  ) {   }

  public openProfile(): void {
    window.location.replace(
         this.configService.config.apiBaseUrl + '/api/account/manage'
    );
  }
}
